"use client";

import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import React, { createContext, useContext, useMemo, useState } from "react";

import { MinMaxInterface } from "@/components/Form/Input/Range/MultiRangeInput";
import { MedusaSorting } from "@/interfaces/Sort/SortInterface";

export interface SearchFilter {
  key: string;
  type: string;
  values: string[];
}

export interface SearchSuggest {
  term: string;
  type: string;
  hits: number;
}

export interface SearchResult {
  nextPage?: number | null;
  products?: PricedProduct[];
  brand?: string[];
  category?: string[];
  filter?: SearchFilter[];
  suggestions?: SearchSuggest[];
  pagination?: {
    currentPage: number;
    pageCount: number;
    resultsPerPage: number;
    total: number;
  };
}

export interface FacetValues {
  recordCount: number;
  selected: boolean;
  title: string;
}

export interface Facet extends FacetBase {
  uiSettings: {
    name: string;
    filterStyle: string;
    selectionType: string;
    showPreviewImages: boolean;
    type: string;
  };
}

export interface FacetBase {
  name: string;
  values: FacetValues[];
}

interface SearchContext {
  showSuggest: boolean;
  setShowSuggest: React.Dispatch<React.SetStateAction<boolean>>;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  searchResult: SearchResult;
  setSearchResult: React.Dispatch<React.SetStateAction<SearchResult>>;
  isLoading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  facets?: Facet[];
  setFacets: React.Dispatch<React.SetStateAction<Facet[] | undefined>>;
  selectedFacets?: FacetBase[];
  setSelectedFacets: React.Dispatch<React.SetStateAction<FacetBase[] | undefined>>;
  activeFacets?: FacetBase[];
  setActiveFacets: React.Dispatch<React.SetStateAction<FacetBase[] | undefined>>;
  removingFacets?: FacetBase[];
  setRemovingFacets: React.Dispatch<React.SetStateAction<FacetBase[] | undefined>>;
  availableSorting?: MedusaSorting[];
  setAvailableSorting: React.Dispatch<React.SetStateAction<MedusaSorting[] | undefined>>;
  priceRange?: MinMaxInterface;
  setPriceRange: React.Dispatch<React.SetStateAction<MinMaxInterface | undefined>>;
}

interface SearchProviderInterface {
  children: React.ReactNode;
}

export const SearchContext = createContext<SearchContext>({
  showSuggest: false,
  setShowSuggest: () => {},
  searchQuery: "",
  setSearchQuery: () => {},
  searchResult: { nextPage: null },
  setSearchResult: () => {},
  isLoading: false,
  setLoading: () => {},
  facets: [],
  setFacets: () => {},
  selectedFacets: [],
  setSelectedFacets: () => {},
  activeFacets: [],
  setActiveFacets: () => {},
  removingFacets: [],
  setRemovingFacets: () => {},
  availableSorting: [],
  setAvailableSorting: () => {},
  priceRange: undefined,
  setPriceRange: () => {},
});

export const SearchProvider = ({ children }: SearchProviderInterface) => {
  const [showSuggest, setShowSuggest] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<SearchResult>({ nextPage: null });
  const [facets, setFacets] = useState<Facet[] | undefined>();
  const [selectedFacets, setSelectedFacets] = useState<FacetBase[] | undefined>();
  const [activeFacets, setActiveFacets] = useState<FacetBase[] | undefined>();
  const [removingFacets, setRemovingFacets] = useState<FacetBase[] | undefined>();
  const [availableSorting, setAvailableSorting] = useState<MedusaSorting[] | undefined>();
  const [priceRange, setPriceRange] = useState<MinMaxInterface | undefined>();

  const providerValue = useMemo(
    () => ({
      showSuggest,
      setShowSuggest,
      searchQuery,
      setSearchQuery,
      isLoading,
      setLoading,
      searchResult,
      setSearchResult,
      facets,
      setFacets,
      selectedFacets,
      setSelectedFacets,
      activeFacets,
      setActiveFacets,
      removingFacets,
      setRemovingFacets,
      availableSorting,
      setAvailableSorting,
      priceRange,
      setPriceRange,
    }),
    [showSuggest, searchQuery, isLoading, searchResult, facets, selectedFacets, activeFacets, removingFacets, availableSorting],
  );

  return <SearchContext.Provider value={providerValue}>{children}</SearchContext.Provider>;
};

export const useSearch = () => {
  const context = useContext(SearchContext);

  if (context === null) {
    throw new Error("useSearch must be used within a SearchProvider");
  }

  return context;
};
