import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";

import { CloseLineIcon } from "../Icon/Close/CloseLineIcon";

interface SearchInputComponentProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  placeholder?: string;
  onReset?: () => void;
  value: string;
}

const SearchInputComponent = ({ placeholder, value, onReset, ...rest }: SearchInputComponentProps) => (
  <div className="relative flex items-center mr-5">
    <MagnifyingGlassIcon className="w-5 sm:w-6 absolute left-2 text-gray-500" />
    <input
      {...rest}
      autoFocus
      className="pl-12 sm:pl-8 text-sm tracking-tighter sm:text-normal border-2 rounded-full xl:w-9/12 w-full border-black p-[0.15rem] focus:border-black focus:outline-none"
      type="search"
      value={value}
      placeholder={placeholder !== undefined ? placeholder : "Finden Sie Ihr Lieblingsprodukt..."}
    />
    <CloseLineIcon className={"w-5 absolute top-[0.3rem] right-2 hover:cursor-pointer lg:box-content xl:right-[calc(25%+.5rem)]"} onClick={onReset} />
  </div>
);

const SearchInput = ({ placeholder, value, onReset, ...rest }: SearchInputComponentProps) => (
  <>
    <div className="hidden md:block ml-2 lg:w-9/12 w-full pl-10 pr-10">
      <SearchInputComponent value={value} onReset={onReset} {...rest} />
    </div>
    <div className="md:hidden ml-1 sm:ml-2 lg:w-9/12 w-full pr-0sm:pr-5">
      <SearchInputComponent placeholder={placeholder} value={value} onReset={onReset} {...rest} />
    </div>
  </>
);

export default SearchInput;
