"use client";

import { useCallback, useEffect, useState } from "react";

interface StorageItemInterface {
  name: string;
  value: string;
}

type AddOptions = {
  onDuplicateName: "update" | "ignore" | "readd";
  cappedSize?: number;
};

const dispatchStorageEvent = (name: string) => {
  window.dispatchEvent(new StorageEvent("storage", { key: name }));
};

export const useStorage = (storeName: string) => {
  const [_items, setItems] = useState<StorageItemInterface[]>([]);

  const add = useCallback(
    (_name: string, value: string, options: AddOptions = { onDuplicateName: "update" }): StorageItemInterface | null => {
      const store = localStorage.getItem(storeName);
      if (!store) {
        return null;
      }

      let items: { name: string; value: string }[] = [...JSON.parse(store)];
      const item: StorageItemInterface = { name: _name, value: value };

      const existingIndex = items.findIndex((_item) => _item.name === _name);
      if (existingIndex > -1) {
        if (options.onDuplicateName === "update") {
          items[existingIndex] = item;
        } else if (options.onDuplicateName === "readd") {
          // remove and re-add
          items.splice(existingIndex, 1);
          items.push(item);
        } else {
          // ignore
        }
      } else {
        // append
        items.push(item);
      }

      if (options.onDuplicateName === "readd" || options.onDuplicateName === "update") {
        // deduplicate existing entries
        const uniqueItems = new Map(items.map((item) => [item.name, item.value]));
        items = Array.from(uniqueItems.entries()).map(([name, value]) => ({ name, value }));
      }

      if (options.cappedSize && items.length > options.cappedSize) {
        items = items.slice(-options.cappedSize);
      }

      localStorage.setItem(storeName, JSON.stringify(items));
      setItems(items);
      dispatchStorageEvent(storeName);

      return item;
    },
    [storeName],
  );

  useEffect(() => {
    const updateStorage = function () {
      const storeData = localStorage.getItem(storeName);

      if (!storeData) {
        localStorage.setItem(storeName, "[]");
      } else {
        setItems(JSON.parse(storeData));
      }
    };

    updateStorage();
    window.addEventListener("storage", updateStorage);

    return () => window.removeEventListener("storage", updateStorage);
  }, [storeName]);

  const remove = useCallback(
    (value?: unknown): boolean => {
      const store = localStorage.getItem(storeName);
      if (!store) {
        return false;
      }

      const items = [...JSON.parse(store)];
      const item = items.find((_item) => _item.value === value);

      items.splice(items.indexOf(item), 1);

      localStorage.setItem(storeName, JSON.stringify(items));
      setItems(items);
      dispatchStorageEvent(storeName);

      return true;
    },
    [storeName],
  );

  const clear = useCallback(() => {
    localStorage.removeItem(storeName);
    localStorage.setItem(storeName, "[]");
    setItems([]);

    dispatchStorageEvent(storeName);
  }, [storeName]);

  const valueAsString = useCallback((value: unknown) => JSON.stringify(value), []);

  return { add, remove, _items, clear, valueAsString };
};
