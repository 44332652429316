import { debounce } from "lodash";
import { useEffect, useMemo, useRef } from "react";

export const useDebounce = (callback: () => void, timeout?: number) => {
  const ref = useRef<(() => void) | undefined>();
  const timer = timeout ?? 1000;

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, timer ?? 1000);
  }, [timer]);
};
