import React from "react";

import { HistoryLineIcon } from "@/components/Icon/History/HistoryLineIcon";
import { useStorage } from "@/lib/hooks/use-storage";

import LinkButton from "../../modules/common/components/link-button";

export const SEARCH_STORAGE = "search-items";

export const LastSearch = ({ onClick }: { onClick: (term: string) => void }) => {
  const { _items: items, clear: clearStorage } = useStorage(SEARCH_STORAGE);

  return (
    <div className="hidden sm:block mt-7 pb-10">
      <div className="bg-gray-100">
        <p className="uppercase font-semibold p-2">Letzte Suche</p>
      </div>

      <div className="mt-5">
        {items
          .slice()
          .reverse()
          .map((item) => (
            <div className="mb-3" key={`history-${encodeURI(item.value)}`}>
              <LinkButton onClick={() => onClick(item.value)}>
                <div className="flex flex-row">
                  <HistoryLineIcon className="w-7 mr-5" />
                  <span className="font-semibold">{item.value}</span>
                </div>
              </LinkButton>
            </div>
          ))}

        {items.length < 1 ? (
          <p className="font-bold mt-5">Es wurden keine Einträge gefunden..</p>
        ) : (
          <LinkButton onClick={() => clearStorage()} className="font-bold mt-5">
            Alle löschen
          </LinkButton>
        )}
      </div>
    </div>
  );
};
